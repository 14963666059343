<template>
  <div class="container content">
    <div v-html="contents.page.mainContent"></div>
  </div>
</template>

<script>

export default{
  name:'Results',
  props:['globalData', 'pageId'],
  data(){
    return{

    }
  },
  computed:{
    userLanguage(){
      return this.globalData.init.language
    },
    contents(){
      let language = this.userLanguage
      let contents = this.globalData.contents[language][this.pageId]

      this.globalData.init.head.pageId = this.pageId
      this.globalData.init.head.title = contents.head.title
      this.globalData.init.head.keywords = contents.head.keywords
      this.globalData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>

<style scoped>
</style>